<template>
	<div>Page not found</div>
</template>

<script>
export default {
    name: 'Errors.Error404',
    components: {
        
    },
    data(){
        return {
            page: {
                title: "Page was not found",
            },
            sidebar: {},
            breadcrumbs: []
        };
    },
    mounted(){
        
    }
};
</script>
